import React, { useEffect } from 'react'

/* eslint-disable @typescript-eslint/no-explicit-any */
function useOnScreen(ref: React.RefObject<any>, rootMargin = "0px") {
    const [isIntersecting, setIntersecting] = React.useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting)
            },
            { rootMargin }
        )

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}

export default useOnScreen;
