import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Blurhash } from "react-blurhash";
import useOnScreen from "../hooks/useOnScreen";

interface IImageLazyLoad {
    src: string;
    alt: string;
    blurHash: string;
    onClick?: () => void;
}

const ImageLazyLoad: React.FC<IImageLazyLoad> = (props) => {
    const {src, blurHash, alt, onClick} = props
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const imageWrapper = useRef<HTMLDivElement>(null);
    const isImageVisibleOnScreen = useOnScreen(imageWrapper, "0px");

    const afterLoad = useCallback(() => {
        setTimeout(() => {
            setIsImageLoaded(true);
        }, 1000);
    }, []);

    useEffect(() => {
        if (isImageVisibleOnScreen) {
            afterLoad();
        }
    }, [isImageVisibleOnScreen]);

    const placeholder = useMemo(() => {
        if (isImageLoaded || !blurHash) return <></>;
        return (
            <Blurhash
                hash={blurHash || ""}
                height="100%"
                width="100%"
                resolutionX={32}
                resolutionY={32}
                punch={1}
            />
        );
    }, [isImageLoaded, blurHash]);

    return (
        <div ref={imageWrapper} className="w-full h-full" onClick={onClick}>
            {placeholder}
            {isImageVisibleOnScreen && (
                <img
                    src={src}
                    loading="lazy"
                    alt={alt}
                    onLoad={afterLoad}
                    className={`object-cover h-full w-full rounded-md ${
                        !isImageLoaded ? "hidden" : ""
                    }`}
                />
            )}
        </div>
    );
};

export default ImageLazyLoad;
