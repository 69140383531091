import React, { memo } from 'react'
import { Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
    CardHeader,
    Carousel,
    Card,
    CardBody,
    Typography,
} from '@material-tailwind/react'
import ImagePreloader from "shared/ui/ImageLazyLoad";

import { WorkCardData } from '../types'

import '../../../app/styles/embla.css'

type WorkCardProps = {
    data: WorkCardData
}

// eslint-disable-next-line react/display-name
export const WorkCard = memo((props: WorkCardProps) => {
    const {
        data: { images, description },
    } = props

    const [opened, { open, close }] = useDisclosure(false)

    const [active, setActive] = React.useState<string>(images[0].src)

    return (
        <>
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-expect-error*/}
            <Card className="w-[30rem] h-[40rem] mb-2 sm:max-w-3xl sm:h-[45rem] cursor-pointer">
                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-expect-error*/}
                <CardHeader
                    shadow={false}
                    floated={false}
                    className="h-full"
                >
                    <>
                        {images?.length > 1 ? (
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-expect-error
                            <Carousel loop={true} className="rounded-xl" style={{
                                overflow: 'clip',
                            }}>
                                {images.map((image, index) => (
                                    <ImagePreloader src={image.src} blurHash={image.placeholder} alt={description} onClick={open} key={index}/>
                                ))}
                            </Carousel>
                        ) : (
                            <ImagePreloader src={images[0].src} blurHash={images[0].placeholder} alt={description} onClick={open}/>
                        )}
                    </>
                </CardHeader>
                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-expect-error*/}
                <CardBody className="h-[8rem]" style={{ padding: '1rem' }}>
                    {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                    {/*@ts-expect-error*/}
                    <Typography
                        variant="lead"
                        color="gray"
                        className="font-medium"
                        style={{
                            lineHeight: '1.6rem',
                        }}
                    >
                        {description}
                    </Typography>
                </CardBody>
            </Card>
            <Modal
                opened={opened}
                onClose={close}
                radius={0}
                size={'xl'}
                closeButtonProps={{
                    size: 'xl',
                }}
                fullScreen
                transitionProps={{ transition: 'fade', duration: 200 }}
                styles={{
                    body: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80%',
                    },
                }}
            >
                <div className="grid gap-4 place-items-center">
                    <div>
                        <img
                            className="w-full max-w-full rounded-lg object-contain object-center md:h-[700px] h-[530px]"
                            src={active}
                            alt={description}
                        />
                    </div>
                    <div className="flex justify-center gap-4">
                        {images.map((image, index) => (
                            <div key={index}>
                                <img
                                    onClick={() => setActive(image.src)}
                                    src={image.src}
                                    className="h-28 max-w-full cursor-pointer rounded-lg object-cover object-center"
                                    alt={description}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
})
