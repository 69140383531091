import InstagramLogo from '../../shared/assets/icons/instagramIcon.svg'
import TelegramLogo from '../../shared/assets/icons/telegramIcon.svg'
import WhatsappLogo from '../../shared/assets/icons/whatsappIcon.svg'
import telegramChanelLogo from '../../shared/assets/icons/telegramChanelIcon.svg'

import { SocialLinkType } from './types'

export const socialList: SocialLinkType[] = [
    {
        iconSrc: InstagramLogo,
        altText: 'instagram',
        linkText: 'Instagram',
        link: 'https://www.instagram.com/lublu.cake',
    },
    {
        iconSrc: TelegramLogo,
        altText: 'telegram',
        linkText: 'Telegram',
        link: 'https://t.me/LubluCake',
    },
    {
        iconSrc: WhatsappLogo,
        altText: 'whatsapp',
        linkText: 'Whatsapp',
        link: 'https://wa.me/message/ZKFQDIK536JJC1',
    },
    {
        iconSrc: telegramChanelLogo,
        altText: 'telegram-channel',
        linkText: 'Telegram Channel',
        link: 'https://t.me/lublucakechannel',
    },
]
