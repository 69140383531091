import React, { useCallback, useMemo } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { WorkCard } from 'entities/work'

import {
    PrevButton,
    NextButton,
    usePrevNextButtons,
} from 'shared/ui/EmblaCarouselArrowButtons'

import { workSlideDataList } from '../constants'

export const WorksCarousel = () => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
    const { prevBtnDisabled, nextBtnDisabled } = usePrevNextButtons(emblaApi)

    const handlePrevClick = useCallback(() => {
        if (!prevBtnDisabled && emblaApi) {
            emblaApi.scrollPrev()
        }
    }, [prevBtnDisabled, emblaApi])

    const handleNextClick = useCallback(() => {
        if (!nextBtnDisabled && emblaApi) {
            emblaApi.scrollNext()
        }
    }, [nextBtnDisabled, emblaApi])

    const renderSlides = useMemo(
        () =>
            workSlideDataList.map((work, index) => (
                <div className="embla__slide" key={index}>
                    <div className="embla__slide__number">
                        <WorkCard data={work} />
                    </div>
                </div>
            )),
        []
    )

    return (
        <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">{renderSlides}</div>
            </div>
            <div className="embla__controls">
                <div className="embla__buttons">
                    <PrevButton
                        onClick={handlePrevClick}
                        disabled={prevBtnDisabled}
                    />
                    <NextButton
                        onClick={handleNextClick}
                        disabled={nextBtnDisabled}
                    />
                </div>
            </div>
        </section>
    )
}
