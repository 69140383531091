import React from 'react'
import { MantineProvider } from '@mantine/core'
import { HomePage } from 'pages/social'

import '@mantine/core/styles.css'

function App() {
    return (
        <MantineProvider>
            <HomePage />
        </MantineProvider>
    )
}

export default App
