import React from 'react'
import { SocialLinkType } from '../types'

export const SocialLink = ({
    iconSrc,
    altText,
    linkText,
    link,
}: SocialLinkType) => (
    <div className="social-link">
        <img src={iconSrc} alt={altText} className="social-icon" />
        <a href={link} className="social-text">
            {linkText}
        </a>
    </div>
)
