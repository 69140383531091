import { Button, Modal } from '@mantine/core'

import React from 'react'
import { useDisclosure } from '@mantine/hooks'

import { SocialLink, socialList } from 'entities/social'
import { WorksCarousel } from 'widgets/work'
import { ReactComponent as Logo } from 'shared/assets/logo-red.svg'

import '../../../app/styles/App.css'
import '../../../app/styles/base.css'
import '../../../app/styles/embla.css'

export const HomePage = () => {
    const [opened, { open, close }] = useDisclosure(false)
    return (
        <div className="wrapper">
            <div className="content">
                <div className="logo-wrapper">
                    <Logo width={300} height={250} />
                    <div className="works" onClick={open}>
                        <Button
                            variant="filled"
                            size="lg"
                            radius="xl"
                            color={'#f5a4c7'}
                            style={{
                                color: 'red',
                                width: 118,
                                height: 45,
                                fontSize: '2.5rem',
                                fontWeight: 400,
                            }}
                        >
                            Works
                        </Button>
                    </div>
                </div>

                <div
                    style={{
                        maxWidth: '100%',
                        marginTop: 50,
                    }}
                >
                    {socialList.map(
                        ({ iconSrc, linkText, altText, link }, index) => (
                            <SocialLink
                                key={index}
                                iconSrc={iconSrc}
                                altText={altText}
                                linkText={linkText}
                                link={link}
                            />
                        )
                    )}
                </div>
            </div>
            <Modal
                opened={opened}
                onClose={close}
                centered
                size={'auto'}
                style={{
                    width: 'fit-content !important',
                }}
                radius={'lg'}
                closeButtonProps={{
                    size: 'xl',
                }}
                trapFocus={false}
            >
                <WorksCarousel />
            </Modal>
        </div>
    )
}
